import AWS, { S3 } from "aws-sdk";
 

const ACCESS_KEY = "AKIAY4QLEBA74FEMZBIV";
const SECRET_KEY = "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";

export const TABLE_RECEIPT_IMAGE = "ReceiptImage";
export const TABLE_V1_RECEIPTS_FULL_VAT = "V1-Receipts-Full-Vat";
export const TABLE_ACCOUNTING_SHOP = "V1-Accounting-Shop";

export const dynamoDbClient = new AWS.DynamoDB.DocumentClient({
  apiVersion: "2012-08-10",
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
  region :  'ap-southeast-1',// 'https://dynamodb.ap-southeast-1.console.aws.amazon.com'
});


export const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
  region :  'ap-southeast-1',// 'https://dynamodb.ap-southeast-1.console.aws.amazon.com'

});